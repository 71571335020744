import { useAppSelector } from '../../Storage/hooks';
import { getSystemDescription } from './common';
import { useStyles } from './Styles';
import ReactTooltip from 'react-tooltip';
import { useEffect } from 'react';

export default function SystemDescription() {
    const classes = useStyles();
    const selection = useAppSelector(s => s.store.devicesInProject);
    const allDevices = selection.controller !== null ? selection.modules.concat(selection.controller) : [];
    const isActive = allDevices.length !== 0;

    useEffect(() => { ReactTooltip.rebuild(); });

    if (!isActive) {
        return <div className={classes.emptyWrapper}>
            <div className={classes.inactiveHeaderStyle}>Сведения о системе</div>
        </div>
    }

    const { totalACPowerConsumption, totalPowerConsumption, totalWidth, totalAICount, totalAOCount, totalDICount, totalDOCount }
        = getSystemDescription(allDevices, selection.controller!);

    const content = <div>
        <div className={classes.descriptionHeader}>Мощность</div>
        {toItemComponent('Общая мощность (~230 В), ВА:', totalACPowerConsumption)}
        {toItemComponent('Общая мощность (=24 В), Вт:', totalPowerConsumption, undefined, "Максимальная потребляемая мощность, без учета датчиков и дополнительных внешних устройств")}
        <div className={classes.descriptionWidthMargin}>
            {toItemComponent('Ширина системы, мм:', totalWidth, classes.descriptionInlineHeader)}
        </div>
        <div className={classes.descriptionHeader}>Количество входов/выходов, шт</div>
        {toItemComponent('Аналоговые входы:', totalAICount)}
        {toItemComponent('Аналоговые выходы:', totalAOCount)}
        {toItemComponent('Дискретные входы:', totalDICount)}
        {toItemComponent('Дискретные выходы:', totalDOCount)}
    </div>
    return <div className={classes.wrapper}>
        <div className={classes.headerStyle}>Сведения о системе</div>
        {content}
    </div>

    function toItemComponent(title: string, value: number, titleClass?: string | undefined, tooltip?: string | undefined) {
        return <div className={classes.specItem}>
            <div className={titleClass}>{title}</div>
            {createTooltip()}
            <div>{value}</div>
        </div>

        function createTooltip() {
            return tooltip !== undefined
                ? <div className={classes.tooltipImage}>
                    <img data-tip={tooltip} width='18' height='18' src='/Assets/info.svg' alt={tooltip} />
                </div>
                : null;
        }
    }
}