import { useStyles } from './Styles';

interface FilterToolTipProps {
    tooltip?: string | null
}

export const FilterToolTip = (props: FilterToolTipProps) => {
    const classes = useStyles();

    const tooltip = props.tooltip != null
        ? <div className={classes.tooltipImageContainer}>
            <img data-tip={props.tooltip} width='18' height='18' src='/Assets/info.svg' alt={props.tooltip} />
        </div>
        : null;

    return tooltip
}