import { createUseStyles } from 'react-jss'
import * as parentStyle from '../../AppStyles'

const focusColor = '#BABABA';

const buttonStyle = {
    backgroundColor: parentStyle.brandColor,
    color: 'white',
    borderRadius: 6,
    borderStyle: 'none',
    cursor: 'pointer',
    padding: 10,
    fontSize: parentStyle.globalFontSize,
}

const flexRow = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
}

const deviceWrapper = {
    margin: 6,
    borderStyle: 'solid',
    borderWidth: 0.5,
}

const deviceInGroup = {
    extend: flexRow,
    marginLeft: 40,
    marginRight: 5,
    marginTop: 10,
    marginBottom: 10,
    padding: '10px 15px 10px 10px',
    backgroundColor: '#E5E5E5',
    '& > :last-child': {
        marginLeft: 'auto',
    },
    '& > div': {
        margin: 5,
    }
}

const groupHeader = {
    extend: flexRow,
    userSelect: 'none',
    borderStyle: 'solid',
    borderColor: parentStyle.borderColor,
    padding: 5,
    paddingRight: 50,
    margin: 5,
    borderWidth: 2,
    fontSize: 14,
}

const moduleGroupHeader = {
    extend: groupHeader,
    fontSize: parentStyle.headerFontSize,
    padding: '25px 50px 25px 30px',
}

export const useStyles = createUseStyles({
    wrapper: {
        extend: parentStyle.styles.groupBorder,
        borderWidth: '2px 2px 0px 2px',
        height: '100%',
        marginLeft: 0,
        marginRight: 0,
        padding: 20,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },

    searchInputWrapper: {
        extend: flexRow,
        minHeight: 40,
        marginLeft: 6,
        marginRight: 6,
        marginBottom: 10,
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: 5,
    },

    searchBox: {
        flexGrow: 0.4,
        minWidth: 100,
        maxWidth: 340,
        padding: 7,
        borderWidth: 2,
        borderColor: '#DDDDDD',
        borderStyle: 'solid',
        fontSize: parentStyle.globalFontSize,
        '&:focus': {
            outline: 'none',
            borderColor: focusColor,
        }
    },
    emptyDevicesSearchResult: {
        extend: parentStyle.styles.inactiveHeader,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
    },
    deviceWrapper: deviceWrapper,
    deviceImage: {
        height: 50,
        margin: 5,
    },
    deviceGroup: {
        extend: deviceWrapper,
        display: 'flex',
        flexDirection: 'row',
        '& > :last-child': {
            marginLeft: 'auto',
        },
    },

    selectDeviceButton: {
        alignSelf: 'center',
        margin: 5,
    },
    changeModuleCountGroup: {
        extend: flexRow,
        gap: 10,
        marginRight: '0px !important',
    },
    changeModuleCountButton: {
        cursor: 'pointer',
        width: 33,
        height: 33,
    },
    filters: {
        extend: flexRow,
        margin: 6,
        gap: 8,
        flexWrap: 'wrap',
    },
    selectedFilter: {
        extend: flexRow,
        borderRadius: 4,
        backgroundColor: '#E5E5E5',
        color: '#686665',
        fontSize: 14,
        padding: '3px 7px',
        alignSelf: 'center',
        userSelect: 'none',
        gap: 5,
    },

    deviceGroupDescription: {
        margin: 5,
        display: 'flex',
        flexDirection: 'row'
    },

    deviceInGroup: deviceInGroup,
    evenDeviceInGroup: {
        extend: deviceInGroup,
        backgroundColor: 'transparent',
    },
    '@media screen and (max-width: 1023px)': {
        deviceInGroup: {
            extend: deviceInGroup,
            flexWrap: 'wrap',
        },
        evenDeviceInGroup: {
            extend: deviceInGroup,
            backgroundColor: 'transparent',
            flexWrap: 'wrap',
        },
        wrapper: {
            borderWidth: 0,
        },
    },

    groupHeader: groupHeader,
    groupTitleHeader: {
        fontSize: parentStyle.globalFontSize,
        fontWeight: 'bold',
    },
    plcDeviceDescription: {
        fontSize: 14,
    },
    plcDeviceDescriptionSelected: {
        fontSize: 14,
        fontStyle: 'bold',
    },
    moduleGroupHeader: moduleGroupHeader,
    moduleInProjectGroupHeader: {
        extend: moduleGroupHeader,
        borderColor: parentStyle.brandColor,
    },
    selectedControllerGroupHeader: {
        extend: groupHeader,
        borderColor: parentStyle.brandColor,
    },

    arrowOpened: {
        extend: parentStyle.styles.arrowBottom,
        alignSelf: 'center',
    },
    arrowClosed: {
        extend: parentStyle.styles.arrow,
        alignSelf: 'center',
    },

    imageButton: {
        cursor: 'pointer',
        width: 14,
        height: 14,
    },
    selectedPlcLabel: {
        extend: flexRow,
        gap: 8,
        color: parentStyle.brandColor,
        fontWeight: 'bold',
        paddingTop: 4.5,
        paddingBottom: 4.5,
    },
    selectedPlcIcon: {
        width: 20,
        height: 16,
    },
    buttonStyle: buttonStyle,
    buttonStyleDisabled: {
        extend: buttonStyle,
        cursor: 'default',
        color: parentStyle.inactiveColor,
        backgroundColor: '#F4F2F2',
        pointerEvents: 'none',
    }
})