import Collapsible from 'react-collapsible';
import { useAppSelector, useAppDispatch } from '../../Storage/hooks';
import { Device } from '../../configuration';
import { ModuleDevice } from '../../configuration';
import { addModule, deleteDevice } from '../../Storage/storeSlice';
import { AppDispatch } from '../../Storage/store';
import { useStyles } from './Styles';
import { useAppStyles } from '../../AppStyles';

export default function DevicesModulesGroup() {
    const classes = useStyles()
    const appStyle = useAppStyles()
    const deviceGroups = useAppSelector(state => state.store.filteredDevicesGroups)
    const modulesInProject = useAppSelector(state => state.store.devicesInProject).modules;
    const dispatch = useAppDispatch()

    const isSingleGroup = deviceGroups.length === 1;

    const content = deviceGroups
        .map((g) => {
            const devices = g.devices.map((d, i) => {
                const isEven = i % 2 !== 0;
                return (
                    <div className={isEven ? classes.evenDeviceInGroup : classes.deviceInGroup} key={d.title}>
                        <img className={classes.deviceImage} src={`Assets/DevicesImages/${d.image}`} alt={d.title}></img>
                        <div className={appStyle.boldText}>{d.title}</div>
                        <div>
                            <div>{d.description}</div>
                            <div style={{ fontSize: 13, marginTop: 10, marginRight: 10 }}>{(d as ModuleDevice).fullDescription}</div>
                        </div>
                        {selectionControl(d)}
                    </div>
                );
            })
            const arrowId = g.title;
            const isProjectContainsModuleFromGroup = g.devices.some(d => modulesInProject.some(moduleInProject => moduleInProject.title === d.title));
            const header = <div
                className={isProjectContainsModuleFromGroup ? classes.moduleInProjectGroupHeader : classes.moduleGroupHeader}>
                {g.title}
                <div id={arrowId} className={isSingleGroup ? classes.arrowClosed : classes.arrowClosed} />
            </div>

            return <Collapsible key={g.title}
                trigger={header}
                transitionTime={150}
                open={isSingleGroup}
                onOpening={() => {
                    const header = document.getElementById(arrowId)
                    header!.className = classes.arrowOpened
                }}
                onClosing={() => {
                    const header = document.getElementById(arrowId);
                    header!.className = classes.arrowClosed;
                }}>
                {devices}
            </Collapsible>;
        })

    return <div>
        {content}
    </div>

    function selectionControl(module: Device) {
        const modulesOfSameTypeCount = modulesInProject
            .map(m => m.title)
            .filter(title => title === module.title)
            .length;

        if (modulesOfSameTypeCount === 0) {
            return <button className={classes.buttonStyle} onClick={() => onAddDevice(module, dispatch)}>Добавить</button>
        }
        else {
            return <div className={classes.changeModuleCountGroup}>
                <img src='/Assets/minus.svg' className={classes.changeModuleCountButton} onClick={() => onRemoveDevice(module, modulesInProject.lastIndexOf(module), dispatch)} alt='remove' />
                <div>{modulesOfSameTypeCount}</div>
                <img src='/Assets/plus.svg' className={classes.changeModuleCountButton} onClick={() => onAddDevice(module, dispatch)} alt='add' />
            </div>
        }
    }
}

function onAddDevice(module: Device, dispatch: AppDispatch) {
    dispatch(addModule(module));
}

function onRemoveDevice(module: Device, position: number, dispatch: AppDispatch) {
    dispatch(deleteDevice({ device: module, position: position }));
}