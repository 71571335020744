import { useStyles } from './Styles';
import { useAppSelector } from '../../Storage/hooks';
import { Software } from '../../configuration';
import { trySendGoogleEvent, trySendYandexEvent } from '../../analyticsHelper';

export default function RecomendedSoftware() {
    const classes = useStyles();
    const selection = useAppSelector(s => s.store.devicesInProject);
    const software = useAppSelector(s => s.store.configuration?.software) ?? [];
    const softwareByDevices = selection.controller !== null
        ? selection.modules.concat(selection.controller).flatMap(d => d.software ?? []).filter(onlyUnique)
        : [];
    const recomendedSoftware = software
        .filter(s => softwareByDevices.some(id => id === s.id))
        .map(s => {
            return <div className={classes.softItem} key={s.id}>
                <div>{s.name}</div>
                {s.isFile
                    ? <a href={s.url} target={"_blank"} key={s.id} rel="noreferrer" onClick={() => sendAnalytics(s)}><img className={classes.downloadImageLink} src="/Assets/download.svg" alt={`download ${s.name}`} /></a>
                    : <a href={s.url} target={"_blank"} key={s.id} rel="noreferrer" onClick={() => sendAnalytics(s)}>На сайт</a>}
            </div>
        });

    const isActive = recomendedSoftware.length !== 0;
    return <div className={isActive ? classes.wrapper : classes.emptyWrapper}>
        <div className={isActive ? classes.headerStyle : classes.inactiveHeaderStyle}>Програмное обеспечение</div>
        <div className={classes.softWrapper}>
            {recomendedSoftware}
        </div>
    </div>
}

function onlyUnique(value: any, index: number, self: Array<any>) {
    return self.indexOf(value) === index;
}

function sendAnalytics(soft: Software) {

    let yandexEvent = '';
    switch (soft.id) {
        case 'owenconfig': {
            yandexEvent = 'plc_conf_ext_OwenConfigurator';
            break;
        }
        case 'oldconfig': {
            yandexEvent = 'plc_conf_ext_mx110';
            break;
        }
        default: {
            yandexEvent = 'plc_conf_ext_Codesys';
            break;
        }
    }

    trySendYandexEvent(3419323, yandexEvent);
    trySendGoogleEvent('Переход', soft.name);
}