export function trySendGoogleEvent(eventType: string, data: string) {
    const googleAnalytics = (window as any).ga;

    if (googleAnalytics === undefined || googleAnalytics.getAll === undefined)
        return;

    googleAnalytics.getAll()[0].send('event', 'Подобрать контроллер', eventType, data);
}

export function trySendYandexEvent(code: number, target: string, data?: object) {
    try {
        ym(3419323, 'reachGoal', target, data);
    }
    catch
    {
        //игнорируем
    }

}