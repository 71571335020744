import { useEffect } from 'react';
import { useAppDispatch } from '../../Storage/hooks'
import { selectDevice, deleteDevice } from '../../Storage/storeSlice'
import { Device, powerType } from '../../configuration'
import { useStyles } from './Styles'


interface DeviceComponentProps {
    device: Device,
    position: number,
    isSelected: boolean,
    controllerPowerType: powerType
}

export default function DeviceComponent(props: DeviceComponentProps) {
    const classes = useStyles();
    const dispatcher = useAppDispatch();
    const { device, position, isSelected, controllerPowerType }: DeviceComponentProps = props;

    useEffect(subscribeToKeyDownEven);

    return <div data-tip={deviceSpecToTooltip(device, controllerPowerType)}>
        <div className={classes.deviceContainer} 
            style={{width: `${device.width * 1.4}px`}}
            onPointerOver={_ => dispatcher(selectDevice({ device: device, position: position }))}
            onPointerLeave={_ => dispatcher(selectDevice(null))}>
            <img 
                className={classes.deviceImage} 
                src={`Assets/DevicesImages/${device.imageScheme}`} 
                alt={device.title} />
            <div className={isSelected ? classes.deviceSelection : classes.deviceOverlay}
                onTouchStart={_ => dispatcher(selectDevice(isSelected ? null : { device: device, position: position }))} />
            {deleteDeviceButton(isSelected && position !== -1)}
        </div>
    </div>;

    function subscribeToKeyDownEven() {
        if (isSelected && position !== -1) {
            document.addEventListener("keydown", onKeydown);
            return () => document.removeEventListener("keydown", onKeydown);
        }
        return () => { };
    }

    function onKeydown(e: KeyboardEvent) {
        if (e.key === "Delete") {
            dispatcher(deleteDevice(props));
        }
    }

    function deleteDeviceButton(canDelete: boolean) {
        return canDelete
            ? <img src='Assets/removeItem.svg' className={classes.deviceDeleteButton} onClick={_ => dispatcher(deleteDevice(props))} alt='delete'/>
            : null;
    }
}

function deviceSpecToTooltip(device: Device, controllerPowerType: powerType) {
    const builder = [device.title];
    const shouldAddIOInfo = (io: number | undefined) => io !== undefined && io !== 0;

    if (shouldAddIOInfo(device.AI)) {
        builder.push(`AI: ${device.AI}`)
    }
    if (shouldAddIOInfo(device.AO)) {
        builder.push(`AO: ${device.AO}`)
    }
    if (shouldAddIOInfo(device.DI)) {
        builder.push(`DI: ${device.DI}`)
    }
    if (shouldAddIOInfo(device.DO)) {
        builder.push(`DO: ${device.DO}`)
    }
    builder.push(`Мощность: ${device.powerConsumption} ${powerUnit(device.powerType, controllerPowerType)}`);
    return builder.join('<br/>');
}


function powerUnit(deviceType: powerType, controllerType: powerType): string {
    if (deviceType === 'DC') {
        return 'Вт';
    }
    else if (deviceType === 'AC') {
        return 'ВА';
    }
    else {
        return powerUnit(controllerType, 'DC');
    }
}