import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState, SelectionTypeState } from './store'
import * as configuration from '../configuration'
import { trySendGoogleEvent, trySendYandexEvent } from '../analyticsHelper';
import ReactTooltip from 'react-tooltip';

type ObjectAlias = object;

//Группа фильтров к индивидуальным фильтрам
interface FiltersGroup extends ObjectAlias {
    [key: string]: FilterGroup;
}

interface FiltersObject extends ObjectAlias {
    [key: string]: string
}

class FilterGroup {
    filtersObject: FiltersObject;
    filterGroupType: configuration.filterType = "AND";

    constructor(filtersObject: FiltersObject, filterGroupType: configuration.filterType) {
        this.filtersObject = filtersObject;
        this.filterGroupType = filterGroupType;
    }
}

interface FiltersByState {
    controllersFilters: FiltersGroup;
    modulesFilters: FiltersGroup;
}

interface SelectedFilter {
    type: string,
    title: string,
    group: string,
    filterType: configuration.filterType,
}

interface DevicesSelection {
    controller: configuration.Device | null;
    modules: Array<configuration.Device>;
}

interface SelectedDevice {
    device: configuration.Device;
    position: number;
}


export interface Prices extends ObjectAlias {
    [key: string]: number;
}

interface PriceResponse {
    code: string
    count: string
    descr: string
    income: any[]
    mod: string | null
    name: string
    nnn: string
    price: string
    price_base: string
    price_old_base: string | null
    remains: string | null
    ser: string
    term: string
}

export const fetchConfiguration = createAsyncThunk('DevicesConfiguration', async () => {
    const response = await fetch('/DevicesConfiguration.json');
    const result = await response.json() as configuration.Configuration;
    return result;
})

export const fetchPrices = createAsyncThunk('DevicesPrices', async (ids: string[]) => {
    if (ids.length === 0) {
        return [];
    }
    const response = await fetch(`https://owen.ru/upl_files/modules/price_getter/get.php?codes=${ids.reduce((p,c) => `${p};${c}`)}`);
    const result = await response.json() as PriceResponse[];
    return result;
})

export const storeSlice = createSlice({
    name: 'store',
    initialState: {

        searchTerm: "",
        selectedFilters: {
            controllersFilters: {} as FiltersGroup,
            modulesFilters: {} as FiltersGroup,
        } as FiltersByState,

        selectionType: 'plc' as SelectionTypeState,
        devicesInProject: {
            controller: null,
            modules: [],
            psu: []
        } as DevicesSelection,
        selectedDevice: null as SelectedDevice | null,
        filteredDevicesGroups: [] as Array<configuration.DevicesGroup>,

        status: 'loading' as 'loading' | 'succeeded' | 'failed',
        configuration: null as configuration.Configuration | null,
        prices: {} as Prices,
    },
    reducers: {
        addFilter: (state, data: PayloadAction<SelectedFilter>) => { addFilterToState({ store: state }, data.payload) },
        removeFilter: (state, data: PayloadAction<SelectedFilter>) => { removeFilterFromState({ store: state }, data.payload) },
        replaceFilter: (state, filter: PayloadAction<{ new: SelectedFilter, old: SelectedFilter }>) => { replaceFilterInState({ store: state }, filter.payload) },
        clearFilters: (state) => { clearFiltersInState({ store: state }) },
        addModule: (state, device: PayloadAction<configuration.Device>) => {
            state.devicesInProject.modules.push(device.payload);
            sendDeviceSelectionAnalytics(device.payload);
        },
        selectController: (state, device: PayloadAction<configuration.Device>) => {
            state.devicesInProject.controller = device.payload;
            sendDeviceSelectionAnalytics(device.payload);
        },
        clearDevices: (state) => {
            state.devicesInProject = {
                controller: null,
                modules: [],
            }
            state.selectedFilters = {
                controllersFilters: {},
                modulesFilters: {},
            }
            state.searchTerm = "";
            state.filteredDevicesGroups = filterDevicesForSelection({ store: state });
            ReactTooltip.hide();
        },
        changeSearchTerm: (state, searchTerm: PayloadAction<string>) => {
            state.searchTerm = searchTerm.payload;
            state.filteredDevicesGroups = filterDevicesForSelection({ store: state });
        },
        changeSelectionType: (state, selectionType: PayloadAction<SelectionTypeState>) => {
            state.selectionType = selectionType.payload;
            state.searchTerm = "";
            state.filteredDevicesGroups = filterDevicesForSelection({ store: state });
        },
        selectDevice: (state, selected: PayloadAction<SelectedDevice | null>) => { state.selectedDevice = selected.payload; },
        deleteDevice: (state, selected: PayloadAction<SelectedDevice>) => {
            if (state.selectedDevice?.position === selected.payload.position) {
                state.selectedDevice = null;
            }
            state.devicesInProject.modules.splice(selected.payload.position, 1);
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchConfiguration.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchConfiguration.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.configuration = action.payload
                state.filteredDevicesGroups = state.configuration.controllers;
                state.configuration.psu = state.configuration.psu.sort((a,b) => b.power - a.power); 
                const savedProject = window.sessionStorage.getItem('configuration');
                if (savedProject !== null) {
                    const parsed = JSON.parse(savedProject) as DevicesSelection;
                    state.devicesInProject = parsed;
                }
            })
            .addCase(fetchConfiguration.rejected, (state, action) => {
                state.status = 'failed'
            })
            .addCase(fetchPrices.fulfilled,(state, action) => {
                const prices = action.payload;
                for (let i = 0; i < prices.length; i++) {
                    const price = prices[i];
                    state.prices[price.code] = Number.parseFloat(price.price);
                }
            });
    }
})

// Action creators are generated for each case reducer function
export const {
    addFilter,
    removeFilter,
    replaceFilter,
    clearFilters,
    addModule,
    selectController,
    changeSearchTerm,
    clearDevices,
    changeSelectionType,
    selectDevice,
    deleteDevice,
} = storeSlice.actions

export const filteredDevices = (state: RootState) => state.store.filteredDevicesGroups.flatMap(g => g.devices);
export const selectedFilters = (state: RootState) => {
    const group = selectCurrentFilters(state.store.selectionType, state.store.selectedFilters);
    return Object.entries(group)
        .flatMap(groupToFilters => Object.entries(groupToFilters[1].filtersObject)
            .flatMap(typeToTitle => ({
                group: groupToFilters[0],
                type: typeToTitle[0],
                title: typeToTitle[1],
                filterType: groupToFilters[1].filtersObject.filterType as configuration.filterType
            })));
}
export const selectedFiltersInGroup = (state: RootState, group: string) => {
    const selectedGroup = selectCurrentFilters(state.store.selectionType, state.store.selectedFilters)[group]?.filtersObject
    if (selectedGroup !== null && selectedGroup !== undefined) {
        return Object.keys(selectedGroup);
    }
    return [];
};

export function selectCurrentDevices(type: SelectionTypeState, configuration: configuration.Configuration | null) {
    if (configuration === null) {
        return [];
    }
    return type === 'plc'
        ? configuration.controllers
        : configuration.modules;
}

function filterDevicesForSelection(state: RootState): Array<configuration.DevicesGroup> {
    const currentGroup = selectCurrentDevices(state.store.selectionType, state.store.configuration);
    return filterDeviceGroups(currentGroup, state);
}

function filterDeviceGroups(groups: Array<configuration.DevicesGroup>, state: RootState): Array<configuration.DevicesGroup> {
    return groups
        .map(g => (
            {
                title: g.title,
                description: g.description,
                image: g.image,
                devices: filterDevices(g.devices, state.store.selectionType, state.store.selectedFilters, state.store.searchTerm)
            }))
        .filter(g => g.devices.length > 0);
}

function filterDevices(devices: Array<configuration.Device>, selectionType: SelectionTypeState, selectedFilters: FiltersByState, searchTerm: string) {
    const currentFilters = selectCurrentFilters(selectionType, selectedFilters);
    return devices.filter(d => isDeviceFilterable(d, currentFilters, searchTerm));
}


function isDeviceFilterable(device: configuration.Device, filters: FiltersGroup, searchTerm: string): boolean {
    const isNameSatisfiesSearchTerm = searchTerm === "" 
        || device.title.toLocaleUpperCase().indexOf(searchTerm.toLocaleUpperCase()) !== -1

    if (Object.values(filters).length === 0) {
        return isNameSatisfiesSearchTerm
    }

    return isNameSatisfiesSearchTerm && isDeviceAcceptable(device, filters);
}

function isDeviceAcceptable(device: configuration.Device, groups: FiltersGroup) {
    let groupNames = Object.keys(groups);
    for (let i = 0; i < groupNames.length; i++) {
        const name = groupNames[i];
        const group = groups[name].filtersObject;
        const filtersInGroup = Object.keys(group);
        const deviceOptions = device.options.filter(o => o.group === name).map(o => o.type);
        const filterType = groups[name].filterGroupType;
        switch (filterType) {
            case "AND":
                var filterFunction = isDeviceAcceptableByAndFilter;
                break;
            case "OR":
                filterFunction = isDeviceAcceptableByOrFilter;
                break;
            case "AND_Strong":
                filterFunction = isDeviceAcceptableByAndStrongFilter;
                break;
            default:
                throw 'Not Supported';
        }

        if (!filterFunction(deviceOptions, filtersInGroup))
            return false;
    }

    return true;
}

function isDeviceAcceptableByAndFilter(deviceOptions: string[], filtersInGroup: string[]) : boolean {
    const overlappingOptions = deviceOptions.filter(t => filtersInGroup.includes(t));
    return overlappingOptions.length === filtersInGroup.length;
}

function isDeviceAcceptableByOrFilter(deviceOptions: string[], filtersInGroup: string[]): boolean {
    const overlappingOptions = deviceOptions.filter(t => filtersInGroup.includes(t));
    return overlappingOptions.length > 0;
}

function isDeviceAcceptableByAndStrongFilter(deviceOptions: string[], filtersInGroup: string[]): boolean {
    const overlappingOptions = deviceOptions.filter(t => filtersInGroup.includes(t) && deviceOptions.length === filtersInGroup.length);
    return overlappingOptions.length === filtersInGroup.length;
}

function addFilterToState(state: RootState, filter: SelectedFilter) {
    const filters = selectCurrentFilters(state.store.selectionType, state.store.selectedFilters);
    if (filter.group in filters) {
        filters[filter.group].filtersObject[filter.type] = filter.title;
    }
    else {
        filters[filter.group] = new FilterGroup({}, filter.filterType);
        filters[filter.group].filtersObject[filter.type] = filter.title;
    }

    state.store.filteredDevicesGroups = filterDevicesForSelection(state);
}

function removeFilterFromState(state: RootState, filter: SelectedFilter) {
    const filters = selectCurrentFilters(state.store.selectionType, state.store.selectedFilters);
    if (filter.group in filters) {
        delete filters[filter.group].filtersObject[filter.type];

        if (Object.values(filters[filter.group].filtersObject).length === 0) {
            delete filters[filter.group];
        }
    }
    state.store.filteredDevicesGroups = filterDevicesForSelection(state);
}

function replaceFilterInState(state: RootState, filter: { new: SelectedFilter; old: SelectedFilter; }) {
    removeFilter(filter.old);
    addFilter(filter.new);
    state.store.filteredDevicesGroups = filterDevicesForSelection(state);
}

function clearFiltersInState(state: RootState) {
    if (state.store.selectionType === "plc") {
        state.store.selectedFilters.controllersFilters = {};
    }
    else {
        state.store.selectedFilters.modulesFilters = {};
    }
    state.store.filteredDevicesGroups = filterDevicesForSelection(state);
}

function selectCurrentFilters(type: SelectionTypeState, filters: FiltersByState) {
    return type === 'plc'
        ? filters.controllersFilters
        : filters.modulesFilters;
}

function sendDeviceSelectionAnalytics(device: configuration.Device) {
    trySendYandexEvent(3419323, 'plc_conf_ext_add');
    trySendGoogleEvent('Добавить', `модификация прибора (${device.title})`);
}

export default storeSlice.reducer