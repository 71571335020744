import { useAppSelector, useAppDispatch } from '../../Storage/hooks';
import { Device } from '../../configuration';
import { selectController } from '../../Storage/storeSlice';
import { AppDispatch } from '../../Storage/store';
import { useStyles } from './Styles';
import { useAppStyles } from '../../AppStyles';
import Collapsible from 'react-collapsible';

export default function DevicesControllersGroup() {
    const classes = useStyles()
    const appStyle = useAppStyles()
    const deviceGroups = useAppSelector(state => state.store.filteredDevicesGroups)
    const selectedController = useAppSelector(state => state.store.devicesInProject).controller;
    const dispatch = useAppDispatch()

    const isSingleGroup = deviceGroups.length === 1;

    const content = deviceGroups
        .map((g) => {

            const isSelectedDeviceInGroup = selectedController !== null 
                && g.devices.map(c => c.title).includes(selectedController.title);

            const devices = g.devices.map((d, i) => {
                const isEven = i % 2 !== 0;
                const isDeviceSelected = isSelectedDeviceInGroup && d.title === selectedController.title;
                return (
                    <div className={isEven ? classes.evenDeviceInGroup : classes.deviceInGroup} key={d.title}>
                        <div className={appStyle.boldText}>{d.title}</div>
                        <div className={isDeviceSelected ? classes.plcDeviceDescriptionSelected : classes.plcDeviceDescription}>{d.description}</div>
                        {selectionControl(isDeviceSelected, d, selectedController)}
                    </div>
                );
            })

            const groupHeaderClass = isSelectedDeviceInGroup ? classes.selectedControllerGroupHeader : classes.groupHeader;
            const arrowId = g.title;
            const header = <div className={groupHeaderClass}>
                <div className={classes.deviceGroupDescription}>
                    <img className={classes.deviceImage} src={`Assets/DevicesImages/${g.image}`} alt={g.title}></img>
                    <div style={{ marginLeft: '15px' }}>
                        <div className={classes.groupTitleHeader}>{g.title}</div>
                        <div>{g.description}</div>
                    </div>
                </div>
                <div id={arrowId} className={isSingleGroup ? classes.arrowClosed : classes.arrowClosed} />
            </div>

            return <Collapsible
                key={g.title}
                trigger={header}
                transitionTime={150}
                open={isSingleGroup}
                onOpening={() => {
                    const header = document.getElementById(arrowId)
                    header!.className = classes.arrowOpened
                }}
                onClosing={() => {
                    const header = document.getElementById(arrowId);
                    header!.className = classes.arrowClosed;
                }}>
                {devices}
            </Collapsible>;
        })

    return <div>
        {content}
    </div>

    function selectionControl(isDeviceSelected: boolean, current: Device, selectedController: Device | null) {
        if (selectedController === null) {
            return <button className={classes.buttonStyle} onClick={() => onAddDevice(current, dispatch)}>Добавить</button>
        }
        else if (!isDeviceSelected) {
            return <button className={classes.buttonStyle} onClick={() => onAddDevice(current, dispatch)}>Заменить</button>
        }
        else {
            return <div className={classes.selectedPlcLabel}>
                <img src='/Assets/plcSelected.svg' className={classes.selectedPlcIcon} alt="selected" />
                {'Выбран'}
            </div>
        }
    }
}

function onAddDevice(device: Device, dispatch: AppDispatch) {
    dispatch(selectController(device));
}