import { Device, PSU } from '../../configuration';
import { brandColor } from '../../AppStyles';
import { Prices } from '../../Storage/storeSlice';
import { readFileAsDataURL, getSystemDescription, groupDevices } from './common';
import { saveAs } from 'file-saver';
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as pdfMake from 'pdfmake/build/pdfmake'
import { TDocumentDefinitions } from 'pdfmake/interfaces';

const defaultFontsInPdf = pdfMake as any;
defaultFontsInPdf.vfs = pdfFonts.pdfMake.vfs;

export async function saveSpecAsPdf(allDevices: Device[], controller: Device, additionalDevices: { device: PSU, count: number }[], prices: Prices) {

    const imgBlob = await (await fetch('/Assets/logo.png')).blob();
    const imgData = await readFileAsDataURL(imgBlob);

    const docDefinition = {
        content: [
            {
                style: 'tableStyle',
                table: 
                {
                    headerRows: 0,
                    body:
                    [
                        [
                            {
                                image: imgData,
                                width: 128,
                                margin: [0, 0, 0, 15],
                            },
                            {
                                text: '111024, Москва, 2-ая улица Энтузиастов, д.5, корп. 5\nтел.: +7 ( 495) 641-11-56 / факс: +7 (495) 728-41-45\nотдел сбыта: sales@owen.ru\nтех. поддержка 24х7: 8-800-775-63-83, support@owen.ru / owen.ru',
                                style: 'header',
                                margin: [25, 0, 0, 15],
                            }
                        ]
                    ]
                },
                layout: 'noBorders'
            },
            {
                text: 'Сведения о системе',
                style: 'subheader',
            },
            {
                style: 'tableStyle',
                table: {
                    headerRows: 0,
                    body: systemDesсriptionToTable(allDevices, controller)
                },
                layout: 'noBorders'
            },
            {
                text: 'Спецификация',
                style: 'subheader',
            },
            {
                style: 'tableStyle',
                table: {
                    headerRows: 1,
                    widths: ['*', 'auto', 'auto', 'auto'],
                    body: systemSpecificationToTable(allDevices, prices),
                },
            },
            additionalDevices.length > 0 ? { text: 'Рекомендуем', style: 'subheader', } : null,
            additionalDevices.length > 0 ? 'Блоки питания (максимальная потребляемая мощность, без учета датчиков и дополнительных внешних устройств)' : null,
            additionalDevices.length > 0 ? {
                style: 'tableStyle',
                table: {
                    headerRows: 1,
                    widths: ['*', 'auto', 'auto', 'auto'],
                    body: systemRecomendedToTable(additionalDevices, prices),
                },
            } : null,
        ],
        styles: {
            header: {
                fontSize: 9,
                margin: [0, 0, 0, 10]
            },
            subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5]
            },
            tableStyle: {
                margin: [0, 5, 0, 15]
            },
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black'
            },
            tableFillHeader: {
                bold: true,
                fontSize: 13,
                color: 'black',
                fillColor: '#E2E1E6'
            },
            link: {
                color: brandColor,
                decoration: 'underline',
            }
        },
        defaultStyle: {
            font: 'Roboto',
        },

    } as TDocumentDefinitions;

    const doc = pdfMake.createPdf(docDefinition);
    doc.getBlob(b => saveAs(b, "Specification.pdf"));
}

function systemDesсriptionToTable(addDevices: Device[], controller: Device) {
    const descrition = getSystemDescription(addDevices, controller);

    return [
        [{ text: 'Мощность', style: 'tableHeader' }, ''],
        ['Общая мощность (~230 В), ВА:', descrition.totalACPowerConsumption],
        ['Общая мощность (=24 В), Вт:', descrition.totalPowerConsumption],
        [{ text: 'Ширина системы, мм:', style: 'tableHeader' }, descrition.totalWidth],
        [{ text: 'Количество входов/выходов, шт', style: 'tableHeader' }, ''],
        ['Аналоговые входы:', descrition.totalAICount],
        ['Аналоговые выходы:', descrition.totalAOCount],
        ['Дискретные входы:', descrition.totalDICount],
        ['Дискретные выходы:', descrition.totalDOCount],
    ]
}

function systemRecomendedToTable(additionalDevices: { device: PSU, count: number }[], prices: Prices) {
    return devicesGroupToTable(additionalDevices, prices);
}

function systemSpecificationToTable(allDevices: Device[], prices: Prices) {
    return devicesGroupToTable(groupDevices(allDevices), prices);
}

function devicesGroupToTable(devices: { device: Device, count: number }[], prices: Prices) {
    const rows = devices
        .map(pair => [
            { text: pair.device.title, link: pair.device.url, style: 'link' },
            pair.count,
            prices[pair.device.code] ?? '—',
            prices[pair.device.code] !== undefined ? prices[pair.device.code] * pair.count : '—'
        ]);
    return [
        [{ text: 'Наименование', style: 'tableFillHeader' }, { text: 'Количество', style: 'tableFillHeader' }, { text: 'Стоимость', style: 'tableFillHeader' }, { text: 'Сумма', style: 'tableFillHeader' }],
        ...rows,
        ['', '', { text: 'Итого:', style: 'tableHeader' }, devices.map(d => prices[d.device.code] !== undefined ? prices[d.device.code] * d.count : 0).reduce((p, c) => p + c, 0)]
    ]
}