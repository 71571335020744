import { createUseStyles } from 'react-jss'

export const inactiveColor = '#C4C4C4';
export const borderColor = '#DDDDDD';
export const brandColor = '#62AFB1';

export const headerFontSize = 20;
export const globalFontSize = 16;

const groupBorder = {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: borderColor,
    boxSizing: 'border-box',
}

const arrow =
{
    display: 'inline-block',
    width: 12,
    minWidth: 12,
    height: 12,
    borderTop: '2px solid #000',
    borderRight: '2px solid #000',
    transform: 'rotate(45deg)',
    marginLeft: 'auto',
    marginRight: 10,
    marginTop: 5,
    transition: [['transform', '300ms']]
}

const arrowBottom = {
    extend: arrow,
    transform: 'rotate(135deg)',
    marginTop: 0,
}

export const styles = {
    '@global': {
        'html, body': {
            margin: 0,
            height: '100%',
            overflow: 'hidden',
            fontSize: globalFontSize,
        },
        a: {
            color: brandColor,
        }
    },
    logoContainer: {
        display: 'flex',
        height: '3vh',
        padding: 15,
        '& > :last-child': {
            marginLeft: 'auto',
        },
    },
    logoBorderLine: {
        borderColor: brandColor,
        border: '0px solid',
        borderBottomWidth: 2,
        flexGrow: 1,
        margin: 15,
        marginBottom: 11,
    },
    aboutSystemContainer: {
        extend: groupBorder,
        borderWidth: 0,
        marginRight: 5,
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        gridArea: 'description',
        overflowY: 'auto',
    },
    descriptionGroupSeparator:
    {
        border: '0px solid #C4C4C4',
        borderTopWidth: 2,
        marginLeft: 15,
        marginRight: 15,
        marginTop: 5,
        marginBottom: 5,
    },
    appStyle: {
        backgroundColor: 'white',
        display: 'grid',
        height: '91vh',
        border: '0px solid #eaebee',
        borderTopWidth: 2,
        borderWidth: 2,
        paddingLeft: 5,
        paddingRight: 5,
        gridTemplateColumns: '[column1] 25% [column2] 49% [column3] 25%[end]',
        gridTemplateRows: '[row1] 40% [row2] 35% [row3] 25% [end]',
        gridTemplateAreas: `'filters presentation description'
                                'filters selection description' 
                                'filters selection description'`,
    },
    '@media screen and (max-width: 1023px)': {
        '@global': {
            'html, body': {
                overflow: 'initial',
            }
        },
        appStyle: {
            backgroundColor: 'white',
            display: 'flex',
            gap: 10,
            padding: 5,
            flexDirection: 'column',
            overflowY: 'visible',
        },
        aboutSystemContainer:
        {
            marginRight: 0,
            extend: groupBorder,
            borderWidth: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            gridArea: '',
            overflowY: 'visible',
        }
    },
    filtersZone:
    {
        gridArea: 'filters'
    },
    presentationZone:
    {
        gridArea: 'presentation'
    },
    descriptionZone:
    {
        gridArea: 'description'
    },
    selectionZone:
    {
        gridArea: 'selection'
    },
    specificationZone:
    {
        gridArea: 'specification'
    },
    softZone:
    {
        gridArea: 'soft'
    },
    boldText: {
        fontWeight: 'bold',
    },
    inactiveHeader: {
        color: inactiveColor,
        fontSize: headerFontSize,
    },
    activeHeader: {
        fontSize: headerFontSize,
    },
    smallText: {
        fontSize: 14,
    },
    groupBorder: groupBorder,
    arrow: arrow,
    arrowBottom: arrowBottom,
}

export const useAppStyles = createUseStyles(styles);
