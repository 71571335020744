import { createUseStyles } from 'react-jss'
import * as parentStyle from '../../AppStyles'

const wrapper =
{
    extend: parentStyle.styles.groupBorder,
    borderWidth: 0,
    height: '100%',
    overflowY: 'auto',
}

const deviceTypeFilterGroupHeader =
{
    padding: '12px 20px 10px 15px',
    marginBottom: 5,
    userSelect: 'none',
    display: 'flex',
    backgroundColor: '#E5E5E5',
    fontSize: parentStyle.headerFontSize,
}

const filterWrapper =
{
    display: 'flex',
    alignItems: 'center',
    gap: 8,
}

const hiddenFilter =
{
    display: 'none',
}

export const useStyles = createUseStyles({
    filterWrapper: filterWrapper,
    hiddenFilter: hiddenFilter,
    checkInput: {
        marginRight: 15,
    },
    checkLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
    },
    wrapper: wrapper,
    triggerDeviceTypeFilterGroupHeader: deviceTypeFilterGroupHeader,
    triggerWithBlinkAnimation: {
        extend: deviceTypeFilterGroupHeader,
        animationName: '$blink',
        animationDuration: '650ms',
        animationIterationCount: 4,
        animationDirection: 'alternate',
        animationDelay: '280ms'
    },
    '@keyframes blink': {
        from: { backgroundColor: '#E5E5E5' },
        to: { backgroundColor: parentStyle.brandColor }
    },
    deviceTypeFilterGroup:
    {
        backgroundColor: 'white',
        overflowY: 'auto',
    },
    arrowOpened: parentStyle.styles.arrowBottom,
    arrowClosed: parentStyle.styles.arrow,

    filtersGroupHeader:
    {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        marginTop: 11,
        marginBottom: 11,
        fontWeight: 'bold',
    },
    filtersGroup:
    {
        margin: '12px 6px 24px 18px',
    },
    additionalFiltersButton: {
        borderStyle: 'none',
        backgroundColor: 'transparent',
        color: parentStyle.brandColor,
        textDecoration: 'underline',
        padding: 3,
        fontSize: parentStyle.globalFontSize,
    },
    tooltipImageContainer: {
        display: 'flex',
        alignItems: 'center',
    }
})