import React, { useState } from 'react';
import * as configuration from '../../configuration';
import { addFilter, removeFilter, selectedFilters, selectedFiltersInGroup, filteredDevices } from '../../Storage/storeSlice';
import { useAppDispatch, useAppSelector } from '../../Storage/hooks';
import { useStyles } from './Styles';
import { FilterToolTip } from './Common';

function isFilterSelectionVaild(devices: configuration.Device[], option: string) {
    return devices.some(d => d.options.some(o => o.type === option));
}

function canMultiselectInGroup(currentGroup: configuration.FiltersGroup, currentSelection: { group: string }[]) {
    const isMultiSelectInGroup = currentSelection.map(f => f.group).filter(item => item === currentGroup.type).length > 1;
    if (!isMultiSelectInGroup)
        return true;
    return !currentGroup.isSingleSelectable;
}

export interface MultipleChoiceFilterGroupProps {
    filtersGroup: configuration.FiltersGroup
}

export const MultipleChoiceFilterGroup = (props: MultipleChoiceFilterGroupProps) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const devices = useAppSelector(filteredDevices);
    const allFilters = useAppSelector(state => selectedFilters(state));
    const selectedFiltersForGroup = useAppSelector(state => selectedFiltersInGroup(state, props.filtersGroup.type));
    const currentFilters = new Set(selectedFiltersForGroup);
    const filtersMap = new Map(props.filtersGroup.filters
        .map(f => [f.optionType, {
            title: f.title, type: f.optionType, group: props.filtersGroup.type,
            filterType: props.filtersGroup.filterGroupType as configuration.filterType
        }]));

    const filterChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const filter = filtersMap.get(e.target.id);

        if (filter === undefined) {
            return;
        }

        if (e.target.checked) {
            dispatch(addFilter(filter))
        }
        else {
            dispatch(removeFilter(filter))
        }
    }

    const [isExpanded, setIsExpanded] = useState(false);

    const items = props.filtersGroup.filters
        .map((f) => {
            const isSelected = currentFilters.has(f.optionType);
            const isDisabled = !canMultiselectInGroup(props.filtersGroup, allFilters) || !isFilterSelectionVaild(devices, f.optionType);
            const isHidden = f.isAdditional && !isExpanded;
            return <div className={isHidden ? classes.hiddenFilter : classes.filterWrapper} key={f.optionType}>
                <label  className={classes.checkLabel}>
                    <input
                        type='checkbox'
                        className={classes.checkInput}
                        id={f.optionType}
                        checked={isSelected}
                        disabled={isDisabled}
                        onChange={filterChanged}>
                    </input>
                    <div>{f.title}</div>
                </label>
                <FilterToolTip tooltip={f.description} />
            </div>;
        })
    const header = <div className={classes.filtersGroupHeader}>
        {props.filtersGroup.title}
        <FilterToolTip tooltip={props.filtersGroup.description} />
    </div>

    const additionalFiltersButton = !props.filtersGroup.filters.some(e => e.isAdditional)
        ? classes.hiddenFilter
        : classes.additionalFiltersButton;

    return <div key={props.filtersGroup.type} className={classes.filtersGroup}>
        {header}
        {items}
        <button
            className={additionalFiltersButton}
            onClick={_ => setIsExpanded(!isExpanded)}>{isExpanded ? 'Скрыть' : 'Посмотреть все'}</button>
    </div>
}


export default MultipleChoiceFilterGroup;