import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useAppSelector, useAppDispatch } from '../../Storage/hooks';
import { clearDevices } from '../../Storage/storeSlice';
import { useStyles } from './Styles';
import DevicesRackComponent from './DevicesRack';
import { DeviceWithProjectPosition, DevicesGroup, groupToDevicesOnRack } from './common';
import ReactTooltip from 'react-tooltip';

Modal.setAppElement('#root');

export default function SystemPresentationComponent() {
    const classes = useStyles();
    const devicesInProject = useAppSelector(state => state.store.devicesInProject);
    const selectedDevice = useAppSelector(state => state.store.selectedDevice);
    const [modalIsOpen, setIsOpen] = useState(false);
    const dispatcher = useAppDispatch();

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    if (devicesInProject.controller === null) {
        return <div className={classes.wrapper}>
            <div className={classes.emptyProject}>Для начала работы выберите контроллер</div>
        </div>
    }

    const initialGroup = [
        {
            devices: [
                {
                    device: devicesInProject.controller,
                    position: -1
                } as DeviceWithProjectPosition],
            width: devicesInProject.controller.width
        } as DevicesGroup];

    const groups = devicesInProject.modules
        .map((d, i) => ({ device: d, position: i }))
        .reduce(splitDevicesByWidth, initialGroup);

    const items = groups.map((g, i) => createRackComponent(g, i));

    return <div className={classes.wrapper}>
        <div className={classes.devicesScheme}>
            {items}
        </div>
        <div className={classes.topRightButtonPosition}>
            <img 
                src='/Assets/clearProject.svg' 
                className={classes.imageButton} 
                onClick={warnUserOnClear} 
                alt='clear' 
                title='Очистить схему'/>
            <img 
                src='/Assets/expandScheme.svg' 
                className={classes.imageButton}
                onClick={openModal}
                alt='expand'
                title='Развернуть схему'/>
        </div>
        <Modal
            style={{
                content: {
                    top: '20px',
                    left: '20px',
                    right: '20px',
                    bottom: '20px',
                }
            }}
            onAfterOpen={_ => ReactTooltip.rebuild()}
            closeTimeoutMS={150}
            isOpen={modalIsOpen}>
            <div className={classes.modalHeader}>Просмотр конфигурации</div>
            <div className={classes.modalSchemeContainer}>
                <div className={classes.devicesScheme}>
                    {groups.map((g, i) => <DevicesRackComponent
                        key={i}
                        devices={g.devices}
                        controllerPowerType={devicesInProject.controller!.powerType}
                        width={g.width}
                        selected={selectedDevice}
                        showArrowUp={i !== 0}
                        showArrowDown={groups.length > 1 && i !== groups.length - 1} />)}
                </div>
            </div>
            <div className={classes.topRightButtonPosition}>
                <img src='/Assets/removeItem.svg' className={classes.imageButton} onClick={closeModal} alt="collapse" />
            </div>
        </Modal>
    </div>

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function warnUserOnClear() {
        if (window.confirm('Вы действительно хотите очистить схему?')) {
            dispatcher(clearDevices());
        }
    }

    function createRackComponent(group: DevicesGroup, i: number) {
        return <div
                className={classes.devicesRack}
                key={`rack${i}`}>
                {group.devices.map(d => groupToDevicesOnRack(d, selectedDevice, devicesInProject.controller!.powerType))}
            </div>;
    }
}

function splitDevicesByWidth(groups: DevicesGroup[], current: DeviceWithProjectPosition) {

    const lastGroup = groups[groups.length - 1];
    const currentWidth = current.device.width;
    const canFitOnRack = lastGroup.width + currentWidth <= 800;
    if (canFitOnRack) {
        lastGroup.width += currentWidth;
        lastGroup.devices.push(current);
    }
    else {
        groups.push({ devices: [current], width: currentWidth });
    }

    return groups;
}