import { Device } from '../../configuration'

type powerConsumption = [ac: number, dc: number]

export function selectDevicePowerForSystem(device: Device, controller: Device): powerConsumption {
    const isControllerDcPowered = controller.powerType === 'DC';
    const isDeviceWithUniversalPower = device.powerType === 'universal';

    const ac = device.powerType === 'DC' || (isControllerDcPowered && isDeviceWithUniversalPower) ? 0 : device.powerConsumption;
    const dc = device.powerType === 'AC' || (!isControllerDcPowered && isDeviceWithUniversalPower) ? 0 : device.powerConsumption;

    return [ac, dc]
}

export function getSystemDescription(allDevices: Device[], controller: Device) {
    const result = {
        totalACPowerConsumption: 0,
        totalPowerConsumption: 0,
        totalWidth: 0,
        totalAICount: 0,
        totalDICount: 0,
        totalAOCount: 0,
        totalDOCount: 0,
    }

    for (let i = 0; i < allDevices.length; i++) {
        const device = allDevices[i];
        result.totalWidth += device.width;
        result.totalAICount += device?.AI ?? 0;
        result.totalAOCount += device?.AO ?? 0;
        result.totalDICount += device?.DI ?? 0;
        result.totalDOCount += device?.DO ?? 0;
        const [ac, dc] = selectDevicePowerForSystem(device, controller!);
        result.totalACPowerConsumption += ac;
        result.totalPowerConsumption += dc;
    }

    return result;
}

export function readFileAsDataURL(file: Blob): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = () => {
            resolve(fr.result)
        };
        fr.onerror = reject;
        fr.readAsDataURL(file);
    });
}

export function groupDevices(devices: Device[]) {
    return Array.from(devices
        .reduce((map: Map<String, { device: Device, count: number }>, device) => {
            if (map.has(device.title)) {
                const deviceWithCount = map.get(device.title);
                map.set(device.title, { device: device, count: deviceWithCount!.count + 1 });
            }
            else {
                map.set(device.title, { device: device, count: 1 });
            }
            return map;
        }, new Map<String, { device: Device, count: number }>())
        .values())
}