import { Device, powerType } from '../../configuration';
import DeviceComponent from './DeviceComponent'

export interface DeviceWithProjectPosition {
    device: Device,
    position: number,
}

export interface DevicesGroup {
    devices: DeviceWithProjectPosition[],
    width: number,
}

export function groupToDevicesOnRack(pair: DeviceWithProjectPosition, selectedDevice: { device: Device, position: number} | null, controllerPowerType: powerType) {
    const isSelected = selectedDevice?.position === (pair.position) && selectedDevice?.device === pair.device;
    return <DeviceComponent
        device={pair.device}
        position={pair.position}
        isSelected={isSelected}
        controllerPowerType={controllerPowerType}
        key={`${pair.device}${pair.position}`} />
}