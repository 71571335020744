import { useStyles } from './Styles';
import { changeSearchTerm, selectedFilters, removeFilter, clearFilters } from '../../Storage/storeSlice';
import { useAppDispatch, useAppSelector } from '../../Storage/hooks';
import * as configuration from '../../configuration'

interface FiltersComponentProps {
    group: string,
    type: string,
    title: string,
    filterType: configuration.filterType
}

export default function CurrentFilters() {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const search = useAppSelector(s => s.store.searchTerm);
    const currentFilters = useAppSelector(state => selectedFilters(state));
    const filterComponents = currentFilters.map(f => <FilterComponent group={f.group} type={f.type} title={f.title} key={f.type}
        filterType={f.filterType} />);
    const resetFiltersStyle = currentFilters.length !== 0 ? classes.buttonStyle : classes.buttonStyleDisabled;

    return <>
        <div className={classes.searchInputWrapper}>
            <input
                className={classes.searchBox}
                value={search}
                onChange={(t) => dispatch(changeSearchTerm(t.target.value))}
                placeholder="Поиск в каталоге" />
            <button className={resetFiltersStyle} onClick={_ => dispatch(clearFilters())}>Сбросить фильтры</button>
        </div>
        <div className={classes.filters}>
            {filterComponents}
        </div>
    </>

    function FilterComponent(props: FiltersComponentProps) {
        return <div className={classes.selectedFilter}>
            {props.title}
            <img className={classes.imageButton} src='/Assets/removeItem.svg' onClick={_ => dispatch(removeFilter(props))} alt='remove filter' />
        </div>
    }
}
