import { createUseStyles } from 'react-jss'
import * as parentStyle from '../../AppStyles'

const rackMargin = 35;

const deviceOverlay = {
    width: "100%",
    height: "100%",
    position: 'absolute',
    top: 0,
    left: 0,
}

export const useStyles = createUseStyles({
    wrapper: {
        extend: parentStyle.styles.groupBorder,
        borderWidth: '0px 2px 0px 2px',
        height: '100%',
        overflow: 'auto',
        position: 'relative',
    },
    emptyProject: {
        extend: parentStyle.styles.inactiveHeader,
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        textAlign: 'center',
    },
    modalSchemeContainer: {
    },
    modalHeader: {
        fontSize: parentStyle.headerFontSize,
    },
    devicesScheme: {
        overflowY: 'auto',
        height: '100%',
    },
    devicesRack: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10,
    },
    rackContainer: {
        display: 'inline-block',
        overflowY: 'none',
        marginLeft: rackMargin,
    },
    rackWidthLineContainer: {
        display: 'flex',
        alignContent: 'stretch',
        alignItems: 'center',
        marginTop: -20,
        marginLeft: 13,
        marginRight: 13,
        marginBottom: 0,
    },
    rackWidthText: {
        textAlign: 'center',
    },
    rackWidthLine: {
        border: '0 solid',
        borderTopWidth: 2,
        marginLeft: -8,
        marginRight: -8,
        flexGrow: 1,
    },
    rackWidthEdge: {
        margin: '15px 0px -15px 0px',
        height: 50,
        borderStyle: 'dashed',
        borderColor: 'black',
        borderWidth: '0px 2px 0px 0px'
    },
    rackLeftWidthArrow: {
        display: 'inline-block',
        border: 'solid black',
        borderWidth: '0 2px 2px 0',
        padding: 3,
        transform: 'rotate(135deg)',
    },
    rackRightWidthArrow: {
        display: 'inline-block',
        border: 'solid black',
        borderWidth: '0 2px 2px 0',
        padding: 3,
        transform: 'rotate(-45deg)',
    },
    rackUnderlayContainer: {
        position: 'relative',
    },
    rackImage: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    rackImageContainer: {
        position: 'absolute',
        overflowY: 'visible',
        height: '100%',
        marginLeft: -rackMargin
    },
    deviceImage: {
        verticalAlign: 'middle',
    },
    deviceContainer: {
        position: 'relative',
        display: 'inline-block',
        padding: 2,
    },
    deviceOverlay: deviceOverlay,
    deviceSelection: {
        extend: deviceOverlay,
        backgroundColor: 'lightblue',
        opacity: 0.65,
    },
    deviceDeleteButton: {
        position: 'absolute',
        top: 4,
        right: 4,
        width: 14,
        height: 14,
        cursor: 'pointer',
    },
    topRightButtonPosition: {
        position: 'absolute',
        top: 5,
        right: 7,
    },
    modal: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
    imageButton:
    {
        cursor: 'pointer',
        margin: 10,
        width: 24,
        height: 24,
    },
    '@media screen and (max-width: 1023px)': {
        devicesRack: {
            height: 'auto',
        },
        wrapper: {
            borderWidth: 0,
        },
    },
})