import { createUseStyles } from 'react-jss'
import { styles, brandColor } from '../../AppStyles'

export const useStyles = createUseStyles({
    headerStyle: {
        extend: styles.activeHeader,
        marginBottom: 10,
    },
    inactiveHeaderStyle: {
        extend: styles.inactiveHeader,
    },
    emptyWrapper: {
        height: '100%',
        padding: 15,
    },
    wrapper: {
        height: 'auto',
        padding: 15,
    },
    '@media screen and (max-width: 1023px)': {
        wrapper: {
            marginRight: 0,
        },
    },
    descriptionHeader: {
        extend: styles.boldText,
        marginBottom: 10,
    },
    descriptionInlineHeader: {
        extend: styles.boldText,
    },
    descriptionWidthMargin: {
        margin: '10px 0px 10px 0px'
    },
    descriptionGroupSeparator:{
        border: '0px solid #C4C4C4',
        borderTopWidth: 2,
        width: 80,
        marginTop: 5,
        marginBottom: 5,
    },
    softWrapper: {
        display: 'flex',
        flexDirection: 'column-reverse'
    },
    softItem: {
        display: 'flex',
        marginRight: 10,
        marginTop: 10,
        flexDirection: 'row',
        '& > :last-child': {
            marginLeft: 'auto',
        }
    },
    headerContainer:
    {
        display: 'flex',
        flexDirection: 'row',
        '& > :last-child': {
            marginLeft: 'auto',
        }
    },
    downloadButtonWrapper: {
        display: 'flex',
        gap: 6,
        marginLeft: 12,
        marginRigth: 12,
        userSelect: 'none',
        cursor: 'pointer',
    },
    imageButton: {
        width: 20,
        height: 20,
    },
    downloadImageLink: {
        width: 17,
        height: 17,
    },
    additionalItemsHeader: {
        extend: styles.activeHeader,
        marginTop: 20,
        marginBottom: 10,
    },
    specGrid: {
        display: 'grid',
        columnCount: 3,
        gap: 4,
        gridAutoFlow: 'row',
        gridTemplateColumns: '60% 5% auto',
    },
    specRightColumn: {
        textAlign: 'right',
    },
    specItem: {
        display: 'flex',
        flexDirection: 'row',
        margin: '4px 0px 4px 0px',
        '& > :last-child': {
            marginLeft: 'auto',
        }
    },
    tooltipImage: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 5,
    },
    downloadSpecGroup: {
        display: 'flex',
        gap: 5,
    }
})