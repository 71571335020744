import { configureStore } from '@reduxjs/toolkit';
import storeReducer from './storeSlice';
import { devicesType } from '../configuration';

export const store = configureStore({
  reducer: 
  {
      store: storeReducer
  },
})

export type SelectionTypeState = devicesType;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;