import CurrentFilters from './CurrentFilters';
import DevicesModulesGroup from './DevicesModulesGroup';
import DevicesControllersGroup from './DevicesControllersGroup';
import { useStyles } from './Styles';
import { useAppSelector } from '../../Storage/hooks';

export default function DevicesSelection() {
    const classes = useStyles();
    const selectionType = useAppSelector(s => s.store.selectionType);
    const deviceGroups = useAppSelector(state => state.store.filteredDevicesGroups);

    const mainComponent = deviceGroups.length === 0 
        ? <div className={classes.emptyDevicesSearchResult}>По вашему запросу ничего не нашлось</div>
        : selectionType === 'plc' ? <DevicesControllersGroup /> : <DevicesModulesGroup />;

    return <div className={classes.wrapper}>
        <CurrentFilters />
        {mainComponent}
    </div>
}