import { useEffect, useRef } from 'react';
import Collapsible from 'react-collapsible';
import MultipleChoiceFilterGroup from './MultipleChoiceFilterGroup';
import { FiltersByDeviceType, FiltersGroup, devicesType } from '../../configuration';
import { useAppSelector, useAppDispatch } from '../../Storage/hooks';
import { changeSelectionType } from '../../Storage/storeSlice';
import { useStyles } from './Styles';
import ReactTooltip from 'react-tooltip';

export interface FiltersProps {
    controllers: FiltersByDeviceType,
    modules: FiltersByDeviceType,
}

export const Filters = () => {
    const classes = useStyles();
    const configration = useAppSelector(state => state.store.configuration);
    const selectionState = useAppSelector(s => s.store.selectionType);
    const controller = useAppSelector(s => s.store.devicesInProject).controller;

    const hasUserSelectedControllerFirstTime = usePrevious(controller) === null && controller !== null;

    if (configration === null) {
        return <div />
    }

    const isContollerOpen = 'plc' === selectionState || controller === null;

    return <div className={classes.wrapper}>
        <FiltersByDeviceTypeComponent
            filters={configration.controllersFilters}
            title='Выбор ПЛК'
            type='plc'
            canUserInteract={true}
            isOpen={isContollerOpen} />
        <FiltersByDeviceTypeComponent
            filters={configration.modulesFilters}
            title='Выбор Модуля'
            type='modules'
            canUserInteract={controller !== null}
            isOpen={!isContollerOpen}
            shouldBlink={hasUserSelectedControllerFirstTime} />
    </div>

    function usePrevious(value: any) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

}

export default Filters;

interface FiltersByDeviceTypeProps {
    filters: Array<FiltersGroup>,
    title: string,
    type: devicesType,
    canUserInteract: boolean,
    isOpen: boolean,
    shouldBlink?: boolean,
}

const FiltersByDeviceTypeComponent = (props: FiltersByDeviceTypeProps) => {

    const classes = useStyles();
    const headerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        ReactTooltip.rebuild();
        if (props.shouldBlink) {
            headerRef.current!.scrollIntoView({ behavior: 'smooth' });
            ReactTooltip.show(headerRef.current! as Element);
        }
    });
    const dispatch = useAppDispatch();
    const arrowClass = props.isOpen ? classes.arrowOpened : classes.arrowClosed;
    const arrowId = `FiltersByDeviceType${props.title}`;

    const collapsibleRef = useRef<Collapsible>(null);
    const header =
        <div data-tip={props.shouldBlink ? "Для продолжения перейдите к выбору модулей" : null}
            data-offset="{'right': 100}"
            data-delay-show='280'
            ref={headerRef}
            className={props.shouldBlink ? classes.triggerWithBlinkAnimation : classes.triggerDeviceTypeFilterGroupHeader}>
            {props.title}
            <div id={arrowId} className={arrowClass} />
        </div>

    const collapsible = <Collapsible
        ref={collapsibleRef}
        trigger={header}
        open={props.isOpen}
        handleTriggerClick={props.isOpen ? () => { } : undefined}
        transitionTime={150}
        onOpening={() => {
            const header = document.getElementById(arrowId);
            header!.className = arrowClass;
            dispatch(changeSelectionType(props.type));
        }}
        onClosing={() => {
            const header = document.getElementById(arrowId);
            header!.className = arrowClass;
        }}>
        {props.filters.map(g => <MultipleChoiceFilterGroup filtersGroup={g} key={g.title} />)}
    </Collapsible>

    return <div className={classes.deviceTypeFilterGroup}>
        {props.canUserInteract ? collapsible : header}
    </div>
} 