import { Device, powerType } from '../../configuration';
import { useStyles } from './Styles';
import { DeviceWithProjectPosition, groupToDevicesOnRack } from './common';

interface DeviceRackComponentProps {
    devices: DeviceWithProjectPosition[],
    width: number,
    selected: { device: Device, position: number } | null,
    controllerPowerType: powerType,
    showArrowDown: boolean,
    showArrowUp: boolean,
}

export default function DeviceRackComponent(props: DeviceRackComponentProps) {
    const classes = useStyles();
    const upArrow = props.showArrowUp
        ? <img src='Assets/arrowUpToRack.svg' alt='up' style={{ margin: '10px' }} />
        : <div style={{ minWidth: '104px' }}></div>
    const devices = <div className={classes.devicesRack}>{props.devices.map(d => groupToDevicesOnRack(d, props.selected, props.controllerPowerType))}</div>;
    return <div style={{ display: 'flex' }}>
        {upArrow}
        <div className={classes.rackContainer}>
            <div className={classes.rackWidthText}>{`${props.width} мм`}</div>
            <div className={classes.rackWidthLineContainer}>
                <div className={classes.rackWidthEdge} />
                <div className={classes.rackLeftWidthArrow} />
                <div className={classes.rackWidthLine} />
                <div className={classes.rackRightWidthArrow} />
                <div className={classes.rackWidthEdge} />
            </div>
            <div className={classes.rackUnderlayContainer}>
                <div className={classes.rackImageContainer}>
                    <div className={classes.rackImage}>
                        <img src='Assets/rack.svg' alt='rack' style={{ height: '90px', width: '1300px' }} />
                        <img src='Assets/arrowDownToRack.svg' 
                            style={{ display: props.showArrowDown ? 'initial' : 'none', padding: '75px 0px 0px 10px', overflowY: 'visible' }} 
                            alt='down' />
                    </div>
                </div>
                {devices}
            </div>
        </div>
    </div>
}