import React from 'react';
import './App.css';
import ReactTooltip from 'react-tooltip';
import SystemDescription from './Components/SystemInfo/SystemDescription';
import SystemPresentationComponent from './Components/SystemPresentation/SystemPresentationComponent';
import Specification from './Components/SystemInfo/Specification';
import { Filters } from './Components/Filters/FiltersComponent';
import DevicesSelection from './Components/DevicesSelection/DevicesSelection';
import RecomendedSoftware from './Components/SystemInfo/RecomendedSoftware';
import { useDispatch } from 'react-redux';
import { fetchConfiguration } from './Storage/storeSlice';
import { useAppStyles, brandColor } from './AppStyles';
import { useAppSelector } from './Storage/hooks';

function App() {
    const classes = useAppStyles();
    const dispatch = useDispatch();
    const devices = useAppSelector(s => s.store.devicesInProject);
    React.useEffect(() => {
        dispatch(fetchConfiguration())
    }, [dispatch]);

    React.useEffect(() => {
        window.addEventListener('beforeunload', () => {
            window.sessionStorage.setItem("configuration", JSON.stringify(devices));
        })
    })

    return <>
        <ReactTooltip className='tooltip'
            effect='solid'
            backgroundColor={brandColor}
            multiline={true}
        />
        <div>
            <div className={classes.logoContainer}>
                <a href='https://owen.ru/' target='_blank' rel="noreferrer">
                    <img src='Assets/logo.svg' alt='logo2' style={{ height: '4vh' }} />
                </a>
                <div className={classes.logoBorderLine}></div>
                <img src='Assets/tools.svg' alt='logo2' />
            </div>
            <div className={classes.appStyle}>
                <div className={classes.presentationZone}><SystemPresentationComponent /></div>
                <div className={classes.filtersZone}><Filters /></div>
                <div className={classes.selectionZone}><DevicesSelection /></div>
                <div className={classes.aboutSystemContainer}>
                    <SystemDescription />
                    <div className={classes.descriptionGroupSeparator} />
                    <Specification />
                    <div className={classes.descriptionGroupSeparator} />
                    <RecomendedSoftware />
                </div>
            </div>
        </div>
    </>;
}

export default App;
