import { useEffect } from 'react';
import { saveAs } from 'file-saver';
import { useAppSelector, useAppDispatch } from '../../Storage/hooks';
import { Device, PSU } from '../../configuration';
import { selectDevicePowerForSystem, groupDevices } from './common';
import './pdfService';
import { useStyles } from './Styles';
import { fetchPrices, Prices } from '../../Storage/storeSlice';
import { saveSpecAsPdf } from './pdfService';
import { trySendGoogleEvent, trySendYandexEvent } from '../../analyticsHelper';
import * as iconv from '../../../node_modules/iconv-lite';

export default function Specification() {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const selection = useAppSelector(s => s.store.devicesInProject);
    const availablePsuDevices = useAppSelector(s => s.store.configuration)?.psu ?? [];
    const title = 'Спецификация';
    const prices = useAppSelector(s => s.store.prices);
    useEffect(() => {
        const codesToUpdate = selection.modules
            .concat(selection.controller!)
            .filter(d => d !== null)
            .concat(availablePsuDevices)
            .map(d => d.code)
            .filter((c, i, a) => a.indexOf(c) === i)
            .filter(c => prices[c] === undefined);

        dispatch(fetchPrices(codesToUpdate));
    });

    if (selection.controller === null) {
        return <div className={classes.emptyWrapper}>
            <div className={classes.inactiveHeaderStyle}>{title}</div>
        </div>
    }

    const controller = deviceWithCountToComponent({ device: selection.controller, count: 1 })

    const modules = groupDevices(selection.modules)
        .map(deviceWithCountToComponent);

    const dcPowerUsage = selection.modules
        .concat(selection.controller)
        .map(m => selectDevicePowerForSystem(m, selection.controller!))
        .map(pair => pair[1] as number)
        .reduce((p, c) => p + c, 0);

    const additionalPsuDevices = selectAdditionalPsu(dcPowerUsage);

    const additionalPsu = dcPowerUsage !== 0
        ? additionalPsuComponent(additionalPsuDevices)
        : null;

    return <div className={classes.wrapper}>
        <div className={classes.headerContainer}>
            <div className={classes.headerStyle}>{title}</div>
            <div className={classes.downloadSpecGroup}>
                <div
                    className={classes.downloadButtonWrapper}
                    onClick={async _ => await onDownloadPdf([selection.controller!].concat(selection.modules), selection.controller!, additionalPsuDevices, prices)}>
                    <img
                        className={classes.imageButton}
                        alt="download pdf"
                        src="/Assets/download.svg" />
                    pdf
                </div>
                <div
                    className={classes.downloadButtonWrapper}
                    onClick={_ => onDownloadSpec(dcPowerUsage)}>
                    <img
                        className={classes.imageButton}
                        alt="download specification"
                        src="/Assets/download.svg" />
                    csv
                </div>
            </div>
        </div>
        <div className={classes.specGrid}>
            {/* {Хидер} */}
            <div>Приборы</div>
            <div>Шт.</div>
            <div className={classes.specRightColumn}>Цена</div>
            {/* {Тело} */}
            {controller}
            {modules}
            {/* {Футер} */}
            <b style={{ marginTop: 10, gridColumnStart: 'span 2', textAlign: 'right' }}>Итог:</b>
            <div style={{ marginTop: 10 }} className={classes.specRightColumn}>
                {calculateTotalPrice(selection.controller, selection.modules)} ₽
            </div>
        </div>
        {additionalPsu}
    </div>

    function calculateTotalPrice(controller: Device, modules: Device[]) {
        return modules.concat(controller)
            .map(d => prices[d.code] ?? 0)
            .reduce((p, c) => p + c, 0);
    }

    function deviceWithCountToComponent(dev: { device: Device, count: number }) {
        const { device, count } = dev;
        const price = prices[device.code] !== undefined ? prices[device.code] * count : '—';
        return [<a key={device.code + 'link'} href={device.url} target="_blank" rel="noreferrer">{device.title}</a>,
        <div key={device.code + 'count'}>{count}</div>,
        <div key={device.code + 'price'} className={classes.specRightColumn}>{`${price} ₽`}</div>]
    }

    function additionalPsuComponent(devices: Array<{ device: Device, count: number }>) {
        const recomended = 'Рекомендуем';
        const psuText = 'Блоки питания';
        return <div>
            <div className={classes.additionalItemsHeader}>{recomended}</div>
            <div className={classes.specGrid}>
                <div>{psuText}</div>
                <div />
                <div />
                {devices.map(deviceWithCountToComponent)}
            </div>
        </div>
    }

    function selectAdditionalPsu(dcPowerUsage: number) {
        if (availablePsuDevices.length === 0) {
            return [];
        }
        const highestPowerDevice = availablePsuDevices.reduce((p, c) => p.power > c.power ? p : c);
        const dcPowerWithAllowence = dcPowerUsage + dcPowerUsage * 0.1;
        const highestPsuCount = Math.trunc(dcPowerWithAllowence / highestPowerDevice.power);

        const remainedPower = dcPowerWithAllowence % highestPowerDevice.power;
        const secondBestPowerUnit = remainedPower !== 0
            ? availablePsuDevices.reduce((p, c) => c.power >= remainedPower && c.power < p.power ? c : p)
            : null;

        if (highestPowerDevice === secondBestPowerUnit) {
            return [{ device: highestPowerDevice, count: highestPsuCount + 1 }]
        }
        else if (highestPsuCount === 0 && secondBestPowerUnit !== null) {
            return [{ device: secondBestPowerUnit, count: 1 }]

        }
        else if (highestPsuCount !== 0 && secondBestPowerUnit !== null) {
            return [{ device: highestPowerDevice, count: highestPsuCount }, { device: secondBestPowerUnit, count: 1 }]
        }
        else {
            return [{ device: highestPowerDevice, count: highestPsuCount }]
        }
    }

    function generateCsvFile(dcPowerUsage: number) {
        const delimiter = ";";
        return [{ device: selection.controller!, count: 1 }]
            .concat(groupDevices(selection.modules))
            .concat(selectAdditionalPsu(dcPowerUsage))
            .map(d => `${d.device.title}${delimiter}${d.device.url}${delimiter}${d.count}${delimiter}${prices[d.device.code] !== undefined ? prices[d.device.code] * d.count : 0}`)
            .reduce((p, c) => `${p}\n${c}`, "");
    }

    function onDownloadSpec(dcPowerUsage: number) {
        var encoded = iconv.encode(generateCsvFile(dcPowerUsage), "win1251");
        var blob = new Blob([encoded], { type: "text/csv;charset=utf-8" });
        saveAs(blob, "Specification.csv");
        sendAnalytics('csv');
    }

    async function onDownloadPdf(allDevices: Device[], controller: Device, additionalDevices: { device: PSU, count: number }[], prices: Prices) {
        await saveSpecAsPdf(allDevices, controller, additionalDevices, prices);
        sendAnalytics('pdf');
    }

    function sendAnalytics(specType: 'pdf' | 'csv') {
        const devices = [{ device: selection.controller!.title, count: 1 }]
            .concat(groupDevices(selection.modules).map(p => ({ device: p.device.title, count: p.count })));

        trySendYandexEvent(87319734, 'spec', { devices: devices, });
        
        //аналитика основного сайта
        const yandexEvent = specType === 'pdf' ? 'plc_conf_ext_pdf' : 'plc_conf_ext_csv';
        trySendYandexEvent(3419323, yandexEvent);

        const googleEvent = specType === 'pdf' ? 'PDF' : 'CSV';
        trySendGoogleEvent('Скачать', googleEvent);
    }
}

